// StartScreen react component
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useRecoilState } from "recoil"
// custom imports
import gameStateAtom from "../atoms/GameState"
import {db} from "../utils/db"
import { SfxPlay } from "../sfx"

const StartScreen = () => {

  const [gameState, setGameState] = useRecoilState(gameStateAtom)
  const navigate = useNavigate()
  const [games, setGames] = useState([])

  const handleTeamNameChange = (e, team) => {
    let newTeamNames = [...gameState.teamNames]
    newTeamNames[team] = e.target.value
    setGameState({
      ...gameState,
      teamNames: newTeamNames
    })
  }

  const handleGameChange = (event) => {
    setGameState({
      ...gameState,
      selectedGame: parseInt(event.target.value)
    })
  }

  const handleGameModeChange = (event) => {
    // teams play in alternating mode one team at a time    
    // let activeTeam = [0] 
    
    // teams play all at once at the same time
    // if(['all-at-once-competitive', 'all-at-once-non-competitive'].includes(event.target.value)) {
    //   activeTeam = [0,1]
    // }
    const activeTeam = getActiveTeamArray(event.target.value)

    // clone GameState and set new gameMode
    const newGameState = {
      ...gameState,
      gameMode: event.target.value,
      activeTeam,
    }

    // based on the game mode, we need to set the switchQuestions state
    // the modes alternating-till-true and alternating-next-question are always auto
    if(['alternating-till-true', 'alternating-next-question'].includes(event.target.value)) {
      newGameState.switchQuestions = 'auto'
    }

    setGameState(newGameState)
  }

  const handleScoreModeChange = (e) => {
    setGameState({
      ...gameState,
      displayScores: e.target.value,
    })
  }

  const getActiveTeamArray = (gameMode) => {
    if(['all-at-once-competitive', 'all-at-once-non-competitive'].includes(gameMode)) {
      return [0,1]
    }
    return [0]
  }

  const loadInstalledGames = async () => {
    const games = await db.games.toArray()
    setGames(games)
    console.log('loadInstalledGames', games)
  }
  
  useEffect(() => {
    const init = async () => {
      // reset game state to default
      setGameState({
        ...gameState,
        activeTeam: getActiveTeamArray(gameState.gameMode),
        answers: [[],[]],
        firstAnsweredByTeam: [],
        scores: [0,0],
        time: 0,
        showAnswers: false,
      })
      await loadInstalledGames()
    }
    init()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const MeasureTime = () => {
    return (<>
      <label>Zeit messen</label>
      <div>
        <label>
          JA
          <input type="radio" name="trackTimeGroup"
            value="yes" checked={gameState.trackTime === 'yes'}
            onChange={(e) => setGameState({ ...gameState, trackTime: e.target.value })}
          />
        </label>
        <label>
          NEIN
          <input type="radio" name="trackTimeGroup"
            value="no" checked={gameState.trackTime === 'no'}
            onChange={(e) => setGameState({ ...gameState, trackTime: e.target.value })}
          />
        </label>
      </div>
    </>)
  }

  const SelectGame = () => {
    return (<>
      <label>Spiel auswählen:</label>
      <select value={gameState.selectedGame} onChange={handleGameChange}>
        <option value="-1">Bitte Quiz auswählen</option>
        {games.map((game, index) => {
          return <option key={index} value={index}>{game.name}</option>
        })}
      </select>
    </>)
  }

  const SelectGameMode = () => {
    return (<>
      <label>Modus wählen:</label>
      <select value={gameState.gameMode} onChange={handleGameModeChange}>
        <option value="alternating-till-true">Abwechselnd bis richtig</option>
        <option value="alternating-next-question">Abwechselnd nächste Frage</option>
        <option value="all-at-once-competitive">Gleichzeitig - Wettbewerb</option>
        <option value="all-at-once-non-competitive">Gleichzeitig - kein Wettbewerb</option>
      </select>
    </>)
  }

  const SelectQuestionMode = () => {
    return (<>
      <label>Frage wechseln:</label>
      <select value={gameState.switchQuestions} onChange={(e) => setGameState({ ...gameState, switchQuestions: e.target.value })}>
        <option value="manual">manuell</option>
        <option value="auto">automatisch</option>
      </select>
    </>)
  }

  const SelectScoreMode = () => {
    return (<>
      <label>Punkte anzeigen:</label>
      <select value={gameState.displayScores} onChange={handleScoreModeChange}>
        <option value="both">Punkte für beide Teams anzeigen</option>
        <option value="teamA">Punkte für Team A anzeigen</option>
        <option value="teamB">Punkte für Team B anzeigen</option>
        <option value="no">Punkte für kein Team anzeigen</option>
      </select>
    </>)
  }

  return (
    <div className="startScreen">

      <div className="startScreenContainer">
      
        <h2>Einstellungen</h2>
        
        <div className="teamNameInputContainer">
          {(games.length === 0) && <p>
            Keine Spiele vorhanden: <br />
            <button onClick={() => navigate('/sync')} className="game-button">Verfügbare Spiele installieren!</button>
          </p>}

          {(games.length > 0) && <>
            <>
              <label>Team Namen:</label>
              <input key="teamName0" type="text" value={gameState.teamNames[0]} onChange={(e) => handleTeamNameChange(e, 0)} />
              <span style={{fontSize: '25px'}}>vs.</span>
              <input key="teamName1" type="text" value={gameState.teamNames[1]} onChange={(e) => handleTeamNameChange(e, 1)} />          
            </>
            <MeasureTime />
            <SelectQuestionMode />
            <SelectScoreMode />
            <SelectGame />
            <SelectGameMode />
          </>}  
        </div>    

        <div className="actionButtons">
          <button onClick={() => {
            SfxPlay('click')
            navigate('/')
          }} className="game-button orange">Abbrechen</button>
          {(gameState.selectedGame !== -1 && gameState.selectedGame !== '') && <button onClick={() => {
            SfxPlay('click')
            navigate('/quiz')
          }} className="game-button">Start</button>}
        </div>    
      </div>
    </div>
  )
}

export default StartScreen 