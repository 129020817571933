// 3rd party imports
import { useNavigate } from "react-router-dom"
import { useRecoilState } from "recoil"
// custom imports
import { SfxPlay } from "../sfx"
import gameStateAtom from "../atoms/GameState"

const SettingsScreen = () => {

  const chars = ['a','b','c','d','e','f','g','h','i','j','k']
  const navigate = useNavigate()
  const [gameState, setGameState] = useRecoilState(gameStateAtom)

  const handleButtonCharacterChange = (event) => {
    let newPossibleKeys = [...gameState.possibleKeys]
    newPossibleKeys[parseInt(event.target.name.slice(-1))] = event.target.value
    setGameState({
      ...gameState,
      possibleKeys: newPossibleKeys
    })
  }

  const optionTag = (char, block, index) => {
    return (
      (!gameState.possibleKeys.includes(char)) ? 
      <option value={char} key={`charOption${block}${index}`}>{char.toUpperCase()}</option>
      : ''
    )
  }

  const currentChar = block => gameState.possibleKeys[block].toUpperCase()

  return (
    <>
      <div className="startScreen">      

        <h2>Einstellungen</h2>

        <div style={{display: 'flex', flexDirection: 'row', margin: 'auto', width: '50vw', gap: '50px'}}>          
          <div style={{display: 'flex', flexDirection: 'column', gap: '10px', width: '100%'}}>
            <h3>Buttons Team A</h3>
            <label>
              Button 1&nbsp;= {currentChar(0)}&nbsp;
              <select onChange={handleButtonCharacterChange} name="btn0">
                <option value="-1">Button wählen</option>
                {chars.map((char, index) => optionTag(char, 0, index))}                  
              </select>
            </label>
            <label>
              Button 2&nbsp;= {currentChar(1)}&nbsp;
              <select value={gameState.possibleKeys[1]} onChange={handleButtonCharacterChange} name="btn1">
                <option value="-1">Button wählen</option>
                {chars.map((char, index) => optionTag(char, 1, index))}
              </select>
            </label>
          </div>

          <div style={{display: 'flex', flexDirection: 'column', gap: '10px', width: '100%'}}>
            <h3>Buttons Team B</h3>
            <label>
              Button 1&nbsp;= {currentChar(2)}&nbsp;
              <select value={gameState.possibleKeys[2]} onChange={handleButtonCharacterChange} name="btn2">
                <option value="-1">Button wählen</option>
                {chars.map((char, index) => optionTag(char, 2, index))}
              </select>
            </label>
            <label>
              Button 2&nbsp;= {currentChar(3)}&nbsp;
              <select value={gameState.possibleKeys[3]} onChange={handleButtonCharacterChange} name="btn3">
                <option value="-1">Button wählen</option>
                {chars.map((char, index) => optionTag(char, 3, index))}
              </select>
            </label>
          </div>
        </div>
        
        <div className="actionButtons">
          <button onClick={() => {
            SfxPlay('click')
            navigate('/sync')
          }} className="game-button orange">Spiele verwalten</button>
          <button onClick={() => {
            SfxPlay('click')
            navigate('/')
          }} className="game-button">Schliessen</button>
        </div>
      </div>
    </>
  )
}

export default SettingsScreen