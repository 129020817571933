const rndGradient = () => {
    // Define an array of CSS class names
    const classNames = ['bg1', 'bg2', 'bg3'];
    // Generate a random index to select a class
    const randomIndex = Math.floor(Math.random() * classNames.length);
    // Select a random class based on the index
    const randomClass = classNames[randomIndex];
    return randomClass
}

export default rndGradient