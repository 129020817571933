import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"

const GameControl = ({previousQuestion, nextQuestion, stopQuiz}) => {
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate()

  const switchToFullscreen = () => {
    const elem = document.documentElement; // get the root element of the document

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  const gotoStartscreen = () => {
    stopQuiz()
    navigate('/start')
  }

  // const pauseQuiz = () => {
  //   console.log('pauseQuiz')
  // }

  // event handlers go here
  useEffect(() => {
    const keydownListener = (event) => {
      const possibleKeys = ['z','Enter']
      if (possibleKeys.includes(event.key)) {
        // add event listener for keydown
        switch (event.key) {
          case 'z':
            console.log('z pressed')
            // toggle visibility
            setVisible((visible) => !visible)
            break
          case 'Enter':
            // switch to next question if manual mode is enabled
            nextQuestion()
            break;
          default:
            break
        }
      }
    }
    document.addEventListener('keydown', keydownListener)
    // remove event listener for keydown
    return () => {
      document.removeEventListener('keydown', keydownListener)
    }
  }, [visible, setVisible])

  return (
    <>
      {visible && (
        <div className="controls">
          <button onClick={previousQuestion}>Zurück</button>
          <button onClick={nextQuestion}>Nächste</button>
          {/* <button onClick={pauseQuiz}>Pause</button> */}
          <button onClick={switchToFullscreen}>Vollbild</button>
          <button onClick={gotoStartscreen}>Exit</button>
        </div>
      )}
    </>    
  )
}

export default GameControl
