import React, { useEffect } from "react"
import { useRecoilState } from "recoil"
import formatTime from "../utils/FormatTime"
import GameTimerState from "../atoms/GameTimerState"

const GameTimerDisplay = ({ secs }) => {
  return (
    <div className="hudBlock">
      <span>Zeit:</span>{formatTime(secs)}
    </div>
  )
}

const GameTimer = () => {
  const [gameTimerState, setGameTimerState] = useRecoilState(GameTimerState)

  console.log('GameTimer rendered')

  const setGameTimerInterval = (intervalId) => {
    setGameTimerState((gt) => {
      return {
        ...gt,        
        intervalId,
      }
    })
  }

  const setSeconds = () => {
    setGameTimerState((gt) => {
      return {
        ...gt,
        seconds: gt.seconds + 1,
      }
    })
  }

  useEffect(() => {
    // start interval when component mounts
    const intervalId = setInterval(() => {
      setSeconds()
    }, 1000)

    setGameTimerInterval(intervalId)

    // remove interval when component unmounts
    return () => {
      console.log('GameTimer unmounted', intervalId)
      clearInterval(intervalId)
      setGameTimerInterval(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GameTimerDisplay secs={gameTimerState.seconds} />
  )
}

export default React.memo(GameTimer)