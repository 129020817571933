import sndCorrect01 from './snd-correct-01.wav'
import sndCorrect02 from './snd-correct-02.wav'
import sndCorrect03 from './snd-correct-03.wav'
import sndCorrect04 from './snd-correct-04.wav'
import sndError01 from './snd-error-01.wav'
import sndError02 from './snd-error-02.wav'
import sndError03 from './snd-error-03.wav'
import sndError04 from './snd-error-04.wav'
import sndClick from './snd-click.wav'

const sfxs = {
  correct: [sndCorrect01, sndCorrect02, sndCorrect03, sndCorrect04],
  error: [sndError01, sndError02, sndError03, sndError04],
  click: [sndClick],
}

const RndPlay = (sfxType) => {
  // get random sfx
  const samples = sfxs[sfxType]
  const sfxAudio = new Audio()
  sfxAudio.src = samples[Math.floor(Math.random() * samples.length)]
  sfxAudio.play()
}

export const SfxPlay = (sfxType, sndIdx = 0) => {
  const sfxAudio = new Audio()
  sfxAudio.src = sfxs[sfxType][sndIdx]
  sfxAudio.play()
}

export default RndPlay