import React from "react"
import { useRecoilState } from "recoil"
// custom imports
import GameTimer from "./GameTimer"
import gameStateAtom from "../atoms/GameState"


const Hud = ({ questions, current }) => {

    const [gameState] = useRecoilState(gameStateAtom)

    // check if team answered question already
    const checkForAnswer = (team, answerIdx) => {
        if(answerIdx < gameState.answers[team].length && gameState.answers[team][answerIdx] === true) return 'correct'
        if(answerIdx < gameState.answers[team].length && gameState.answers[team][answerIdx] === false) return 'wrong'
        else return ''
    }

    const mapGameModeToLabel = (state) => {
      const stateMap = {
        'alternating-till-true': 'Abwechselnd bis richtig',
        'alternating-next-question': 'Abwechselnd nächste Frage',
        'all-at-once-competitive': 'Gleichzeitig - Wettbewerb',
        'all-at-once-non-competitive': 'Gleichzeitig - kein Wettbewerb',
      }
      return stateMap[state]
    }

    // check if team is active
    const isActiveTeam = (team) => {
        if(gameState.activeTeam.includes(team)) return 'active'
        return ''
    }

    return (
        <div className="hud">
            <div className={"hudBlock team " + isActiveTeam(0)}>
                <span>{gameState.teamNames[0]}</span>{(['both', 'teamA'].indexOf(gameState.displayScores) > -1) ? gameState.scores[0] : ''}
                <div className="questionCountContainer">

                {gameState.gameMode === 'alternating-till-true' && gameState.answers[0] && gameState.answers[0].map((answer, index) => (
                  <div className={"questionCount " + ((answer) ? 'correct' : 'wrong')} key={'aTeamAnswer' + index}></div>
                ))}

                {gameState.gameMode === 'alternating-next-question' && questions && questions.map((question, index) => (
                  <div className={"questionCount " + checkForAnswer(0, index)} key={'aTeamQuestion' + index}></div>
                ))}

                </div>
            </div>

            <div className="hudBlock" style={{textAlign: 'center'}}>
                <span>Frage:</span>{current + 1}/{questions.length}
            </div>
            
            {gameState.trackTime === 'yes' && <GameTimer />}
            
            <div className={"hudBlock team " + isActiveTeam(1)}>
                <span>{gameState.teamNames[1]}</span>{(['both', 'teamB'].indexOf(gameState.displayScores) > -1) ? gameState.scores[1] : ''}
                <div className="questionCountContainer">
                
                {gameState.gameMode === 'alternating-till-true' && gameState.answers[1] && gameState.answers[1].map((answer, index) => (
                  <div className={"questionCount " + ((answer) ? 'correct' : 'wrong')} key={'aTeamAnswer' + index}></div>
                ))}

                {gameState.gameMode === 'alternating-next-question' && questions && questions.map((question, index) => (
                  <div className={"questionCount " + checkForAnswer(1, index)} key={'bTeamQuestion' + index}></div>
                ))}

                </div>
            </div>

            {/* <div className="hudGameMode">
              <strong>Modus: </strong>{mapGameModeToLabel(gameState.gameMode)}
            </div> */}
        </div>
    )

}

export default Hud
