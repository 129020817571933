import { atom } from "recoil"
import LocalPersist from "../utils/LocalPersist"

const GameState = atom({
    key: 'GameState', // unique ID (with respect to other atoms/selectors)
    default: {
        gameMode: 'alternating-till-true',
        teamCount: 2,
        playerPerTeam: 4,
        teamNames: ['Team A', 'Team B'],
        possibleKeys: ['a', 'b', 'c', 'd'],
        scores: [0,0],
        displayScores: 'both',
        switchQuestions: 'auto', // auto, manual
        answers: [[],[]],
        firstAnsweredByTeam: [],
        pointsPerCorrectAnswer: 1,
        trackTime: 'no',
        activeTeam: [0],
        selectedGame: '',
        msToShowAnswers: 3000,
        showAnswers: false,
    },
    effects: [LocalPersist],
  })

  export default GameState