// 3rd party imports
import { useNavigate } from "react-router-dom"
import AppVersion from "../components/AppVersion"
import { SfxPlay } from "../sfx"

const IntroScreen = () => {

  const navigate = useNavigate()

  return (
    <div className="startScreen">
      <img src="/logo.png" alt="logo" style={{width: '50%', display: 'flex', margin: 'auto'}}/>

      <div className="actionButtons">
        <button onClick={() => {
          SfxPlay('click') 
          navigate('/settings')
        }} className="game-button orange">Einstellungen</button>
        <button onClick={() => {
          SfxPlay('click')
          navigate('/start')
        }} className="game-button">Start</button>
      </div>

      <AppVersion />
    </div>
  )
}

export default IntroScreen