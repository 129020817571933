// 3rd party imports
import { useNavigate } from "react-router-dom"
import { useRecoilState } from "recoil"
// import Confetti from 'react-confetti' // https://www.npmjs.com/package/react-confetti
// custom imports
import GameState from "../atoms/GameState"
import GameTimerState from "../atoms/GameTimerState"
import FormatTime from "../utils/FormatTime"
import { SfxPlay } from "../sfx"
// images
import bg1 from '../img/comic-basketball.jpeg'
import bg2 from '../img/comic-clap.jpeg'
import bg3 from '../img/comic-fly.jpeg'
import bg4 from '../img/comic-jump.jpeg'
import bg5 from '../img/comic-soccer.jpeg'
import bg6 from '../img/comic-thumb-up.jpeg'

const ResultScreen = () => {

  const navigate = useNavigate()

  const [gameState] = useRecoilState(GameState)
  const [gameTimerState] = useRecoilState(GameTimerState)

  const handlePress = () => {
    SfxPlay('click')
    navigate('/start')
  }

  const getWinnerTeamName = () => {
    // check which team has the highest score
    if (gameState.scores[0] > gameState.scores[1]) {
      return gameState.teamNames[0]
    } else if (gameState.scores[0] < gameState.scores[1]) {
      return gameState.teamNames[1]
    }
  }

  const getWinnerTeamIndex = () => {
    // check which team has the highest score
    if (gameState.scores[0] > gameState.scores[1]) {
      return 0
    } else if (gameState.scores[0] < gameState.scores[1]) {
      return 1
    }
    // if draw game
    return 0
  }

  const isDrawGame = () => {
    return gameState.scores[0] === gameState.scores[1]
  }

  return (
    <>
      {/* <Confetti /> */}
      <div className="startScreen" style={{
        backgroundImage: 'url(' + [bg1, bg2, bg3, bg4, bg5, bg6][Math.floor(Math.random() * 6)] + ')',
        backgroundColor: 'white'
      }}>
      <div className="startScreen" style={{
        backgroundImage: 'url(' + [bg1, bg2, bg3, bg4, bg5, bg6][Math.floor(Math.random() * 6)] + ')',
        backgroundColor: 'none',
        backgroundPosition: 'right bottom',
      }}>
        {gameState.gameMode === 'all-at-once-non-competitive' && <div style={{display: 'flex', gap: '50px', margin: 'auto', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <div>
            <h2 style={{fontSize: '6cqw'}}>{gameState.teamNames[0]}</h2>
            {(gameState.displayScores === 'both' || gameState.displayScores === 'teamA') && <div className="scoreContainer">
              <div className="score">
                {gameState.scores[0]} 
                <span>Punkt{(gameState.scores[0] > 1) ? 'e' : ''}</span>
              </div>
            </div>}
          </div>
          <div>
            <h2 style={{fontSize: '6cqw'}}>{gameState.teamNames[1]}</h2>
            {(gameState.displayScores === 'both' || gameState.displayScores === 'teamB') && <div className="scoreContainer">
              <div className="score">
                {gameState.scores[1]} 
                <span>Punkt{(gameState.scores[1] > 1) ? 'e' : ''}</span>
              </div>
            </div>}
          </div>
        </div>}

        {gameState.gameMode !== 'all-at-once-non-competitive' && <>
          {isDrawGame() ? <div>
            <h2 style={{fontSize: '6cqw'}}>Unentschieden</h2>
          </div> : <div>
            <h2 style={{fontSize: '6cqw'}}>Gewinner Team<br />
              <span style={{fontSize: '9cqw'}}>{getWinnerTeamName()}</span>
            </h2>
          </div>}

          {gameState.trackTime === 'yes' && <div style={{fontSize: '30px'}}>        
            in {FormatTime(gameTimerState.seconds)}
          </div>}

          <div className="scoreContainer">
            {(gameState.displayScores === 'both') && <div className="score">            
              {gameState.scores[getWinnerTeamIndex()]} 
              <span>Punkt{(gameState.scores[getWinnerTeamIndex()] > 1) ? 'e' : ''}</span>
            </div>}
          </div>
        </>}        
        
        <div className="actionButtons">
          <button onClick={handlePress} className="game-button">OK</button>
        </div>
      </div></div>
    </>
  )
}

export default ResultScreen