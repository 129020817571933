import { atom } from "recoil"

const GameTimerState = atom({
    key: 'GameTimerState', // unique ID (with respect to other atoms/selectors)
    default: {        
      seconds: 0,
      intervalId: null,
    }, 
  })

  export default GameTimerState