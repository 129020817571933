// 3rd party imports
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
// custom imports
import {db} from "../utils/db"
import { SfxPlay } from "../sfx"

const SyncScreen = () => {

  const navigate = useNavigate()

  const backendUrl = 'https://strapi.sherlock.gmbh'

  const [availableGames, setAvailableGames] = useState([])
  const [status, setStatus] = useState('')
  const [installedGames, setInstalledGames] = useState([])

  const getAvailableGames = async () => {
    const populate = ['Vorschau', 'Fragen', 'Fragen.Antworten', 'Fragen.Bild', 'Fragen.Antworten.Bild']
    let populateQuery = populate.map((item, index) => `populate[${index}]=${item}`).join('&')
    const response = await fetch(`${backendUrl}/api/games?${populateQuery}&pagination[pageSize]=500`, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    })
    const result = await response.json()
    // check if result.data is available and an array
    if (Array.isArray(result.data) && result.data.length > 0) {
      setAvailableGames(result.data)
    }
    console.log(result)
  }

  const fetchImage = async (url) => {
    const response = await fetch(url)
    const blob = await response.blob()
    return blob
  }

  const loadInstalledGames = async () => {
    const games = await db.games.toArray()
    setInstalledGames(games)
    console.log('loadInstalledGames', games)
  }

  const installGame = async (availableGamesIdx) => {
    const game = availableGames[availableGamesIdx]
    const questions = [] 

    const questionsOriginals = game.attributes.Fragen

    for(let i = 0; i < questionsOriginals.length; i++) {
      const question = questionsOriginals[i]
      // get answers
      const answers = []
      question.Antworten.forEach((answer) => answers.push(answer.Antwort))      
      // get bg colors
      const colors = [] // answers colors
      question.Antworten.forEach((answer) => colors.push(answer.Hintergrund))
      // new question document
      const questionDoc = {
        question: question.Frage,
        color: question.Hintergrund,
        answers,
        correctAnswer: question.KorrekteAntwort,
        colors,
      }
      // check if question has image
      if (question.Bild && question.Bild.data) {
        const blob = await fetchImage(backendUrl + question.Bild.data.attributes.url)
        questionDoc.image = blob
      }
      // check if answers have images
      const answersImages = []
      for(let a = 0; a < question.Antworten.length; a++) {
        const answer = question.Antworten[a]
        if (answer.Bild && answer.Bild.data) {
          const blob = await fetchImage(backendUrl + answer.Bild.data.attributes.url)
          answersImages.push(blob)
        }
      }
      // add answers images to question
      if (answersImages.length > 0) {
        questionDoc.answersImages = answersImages
      }
      // push new question to questions array
      questions.push(questionDoc)
    }

    // build game object
    const newGame = {
      name: game.attributes.Name,
      description: game.attributes.Beschreibung,
      questions,      
    }
    
    // push game to db
    try {
      const id = await db.games.add(newGame)
      setStatus(`Spiel: ${newGame.name} erfolgreich hinzugefügt (id ${id}).`)
      loadInstalledGames()
    } catch (error) {
      setStatus(`Fehler bei Installation von Spiel ${newGame.name}: ${error}`)
    }
  }

  const uninstallGame = async (gameName) => {
    try {
      await db.games.where('name').equals(gameName).delete()
      setStatus(`Spiel: ${gameName} erfolgreich entfernt.`)
      loadInstalledGames()
    } catch (error) {
      setStatus(`Fehler beim entfernen vom Spiel ${gameName}: ${error}`)
    }
  }

  useEffect(() => {
    const doInit = async () => {
      await loadInstalledGames()
      await getAvailableGames()      
    }
    doInit()
  }, [])

  const hasCover = (game) => {
    return game.attributes.Vorschau && game.attributes.Vorschau.data
  }

  // check if game is already installed by comparing the game name which is unique
  const isInstalled = (game) => {    
    // compare game name with installed games
    for (let g = 0; g < installedGames.length; g++) {      
      if (installedGames[g].name === game.attributes.Name) return true
    }
    return false
  }

  return (
    <div className="startScreen">
      <h2>Spiele verwalten</h2>

      {status !== '' && <p className="infoPopup">{status}</p>}
      
      {availableGames.length > 0 && <div className="cardList">
        {availableGames.map((game, idx) => {
          const { Name, Beschreibung, Vorschau } = game.attributes
          return <div className="card" key={game.id}>
            {(hasCover(game)) ? 
              <img src={backendUrl + Vorschau.data.attributes.formats.thumbnail.url} alt={'Cover ' + Name} /> : 
              <img src={'cover.png'} alt={'Default Cover'} />
            }
            <h3>{Name}</h3>
            <p>{Beschreibung}</p>
            <div className="cardButtons">
              {isInstalled(game) && <>
                <button onClick={() => uninstallGame(Name)} className="game-button red" style={{fontFamily: 'Font Awesome'}}>
                  &#xf2ed;
                </button>
                <button onClick={async () => {
                  await uninstallGame(Name)
                  await installGame(idx)
                }} className="game-button orange" style={{fontFamily: 'Font Awesome'}}>
                  &#xf358;
                </button>
              </>}
              {!isInstalled(game) && <button onClick={() => installGame(idx)} className="game-button">Installieren</button>}
            </div>
          </div>
        })}
      </div>}      

      <div className="listItemCount">{installedGames.length} Spiel{(installedGames.length === 1 ? '' : 'e')} installiert / {availableGames.length} Spiele vorhanden</div>

      {availableGames.length === 0 && <div>Keine Spiele online vorhanden.</div>}
      
      <div className="actionButtons">
        <button onClick={() => {
          SfxPlay('click')
          navigate('/settings')
        }} className="game-button">Zurück</button>
      </div>
    </div>
  )
}

export default SyncScreen