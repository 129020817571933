// 3rd party imports
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
// Pages
import IntroScreen from './pages/IntroScreen'
import StartScreen from './pages/StartScreen'
import QuizScreen from './pages/QuizScreen'
import ResultScreen from './pages/ResultScreen'
import SettingsScreen from './pages/SettingsScreen'
import SyncScreen from './pages/SyncScreen'
// Styles
import './App.css'

function App() {
  console.log('App rendered')  

  return (
    <Router>
      <div className="App">      
        <Routes>
          <Route path="/" element={<IntroScreen />} />
          <Route path="/sync" element={<SyncScreen />} />
          <Route path="/settings" element={<SettingsScreen />} />
          <Route path="/start" element={<StartScreen />} />
          <Route path="/quiz" element={<QuizScreen />}/>
          <Route path="/result" element={<ResultScreen />}/>
        </Routes>      
      </div>
    </Router>        
  )
}

export default App
